let key = {};

let toasterOption = {
  position: "top-right",
  autoClose: 2000,
  hideProgressBar: false,
  closeOnClick: true,
  pauseOnHover: true,
  draggable: true,
  progress: undefined
};

let Envname = "demo";

if (Envname == "prod") {
  const API_URL = "https://prodapi.xcorporate.uk"; //PROD
  key = {
    baseUrl: `${API_URL}`,
    toasterOption: toasterOption,
    BUSDcontract: "0x55d398326f99059fF775485246999027B3197955",
    xcorporatecontract: "0x4D38c7Da576e77B2cd7920Cd297f0c3DA91dECAe",
    XC_PLAN_A: "0xE35e0A932c13f1582B1C6fBf651394b49175bdFE",
    admin_address: "0xCB16DB33a88a601Cd88f2dB0536700A726a26a6e",
    ScanUrl: "https://bscscan.com/tx/",
    NetworkId: 56,
    decimals: 1e18,
    infuraRpc: "https://bsc.publicnode.com",
  };
} else if (Envname == "demo") {
  const API_URL = "https://xcooperateapi.wearedev.team/"; //DEMO
  key = {
    baseUrl: `${API_URL}`,
    toasterOption: toasterOption,
    BUSDcontract: "0x39705E503Bcf16Fd05e176aDe657B3C8b425296E",
    xcorporatecontract: "0x6EC094438C3C313E76Df5faE5A3b30CB58152458",
    XC_PLAN_A: "0x411F73D09805765BAbB6f7721e50DBFf2EdEF945",
    admin_address: "0x80Aa595c83430288E3A75F4D03bE15b72385420F",
    ScanUrl: "https://testnet.bscscan.com/tx/",
    NetworkId: 97,
    decimals: 1e18,
    infuraRpc: "https://data-seed-prebsc-2-s1.bnbchain.org:8545",
  };
} else {
  const API_URL = "http://localhost";
  key = {
    baseUrl: `${API_URL}:3040`,
    toasterOption: toasterOption,
    BUSDcontract: "0x39705E503Bcf16Fd05e176aDe657B3C8b425296E",
    xcorporatecontract: "0x6EC094438C3C313E76Df5faE5A3b30CB58152458",
    XC_PLAN_A: "0x411F73D09805765BAbB6f7721e50DBFf2EdEF945",
    admin_address: "0x80Aa595c83430288E3A75F4D03bE15b72385420F",  
    ScanUrl: "https://testnet.bscscan.com/tx/",
    NetworkId: 97,
    decimals: 1e18,
    infuraRpc: "https://bsc-testnet.publicnode.com",
  };
}

export default key;
